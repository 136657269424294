<template>
  <div>
    <div id="introText">
      Name your pay plans, add percentages, and add team members that belong to the plans. Add as
      many
      plans as needed.
    </div>

    <div id="tableContainer">
      <v-simple-table id="editorTable">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left" style="max-width: 216px">Pay Structure Name</th>
            <th class="text-left" style="max-width: 216px">New Business Rate (NBS)</th>
            <th class="text-left" style="max-width: 216px">Renewal Business Rate (RWL)</th>
            <th class="text-left" style="width:553px;">Team Members</th>
            <th style="width:50px;" v-if="localPlans.length > 1"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(plan,index) in localPlans" :key="plan.id">
            <td class="text-left" style="max-width: 216px">
              <v-text-field
                placeholder="Plan Name"
                v-model="plan.name"
                style="width: 216px"
                :error-messages="getFieldErrors('plans.' + index + '.name')"
                :error="fieldHasErrors('plans.' + index + '.name')"
              />
            </td>
            <td class="text-left"  style="max-width: 216px">
              <v-text-field

                placeholder="Percentage"
                suffix="%"
                style="width: 216px"
                v-model="plan.new_commission_percentage"
                :error-messages="getFieldErrors('plans.' + index + '.new_commission_percentage')"
                :error="fieldHasErrors('plans.' + index + '.new_commission_percentage')"
              />
            </td>
            <td class="text-left" style="max-width: 216px">
              <v-text-field
                placeholder="Percentage"
                suffix="%"
                style="width: 216px"
                v-model="plan.renewal_commission_percentage"
                :error-messages="getFieldErrors('plans.' + index + '.renewal_commission_percentage')"
                :error="fieldHasErrors('plans.' + index + '.renewal_commission_percentage')"
              />
            </td>
            <td class="text-left" style="width:553px;">
              <v-select
                :items="formattedUsers"
                v-model="plan.users"
                multiple
                placeholder="Select Producers"
                style="min-width:300px;"
                chips
                small-chips
                :error-messages="getFieldErrors('plans.' + index + '.users')"
                :error="fieldHasErrors('plans.' + index + '.users')"
                id="userSelector"
              ></v-select>
            </td>
            <td class="text-right" style="width:50px;" v-if="localPlans.length > 1">
              <div style="width:25px;display:inline-block;padding-top:8px;">
              <v-btn icon @click.prevent="removePlan(plan)" v-if="localPlans.length > 1">
                <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
              </v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="mt-8">
        <v-btn plain color="gray" @click.prevent="addPlan">
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          Add Plan
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {mdiPlus, mdiTrashCanOutline} from '@mdi/js'
import _ from "lodash";
import HasFormErrorsMethodsOnly from "@/config/mixins/HasFormErrorsMethodsOnly";
import {mapGetters} from "vuex";

export default {
  mixins: [HasFormErrorsMethodsOnly],
  props: [
    'value', 'validationExceptions'
  ],
  data() {
    return {
      icons: {
        mdiPlus,
        mdiTrashCanOutline
      },
      localPlans: [],
      users: [],
      loading: {
        users: true
      }
    }
  },
  methods: {
    changeToSinglePayPlan() {
      this.$emit('changePlanType', 'single');
    },
    addPlan() {
      this.localPlans.push({
        id: Math.random(),
        name: '',
        new_commission_percentage: '0',
        renewal_commission_percentage: '0',
        teamMembers: []
      })
    },
    removePlan(plan) {
      this.localPlans = _.without(this.localPlans, plan);
    },
    loadUsers() {
      this.$api.users.index().then(({data}) => {
        this.users = data
        this.loading.users = false
      })
    },
    loadPlans(){
      if(this.commissionSetup.commission_){

      }
    }
  },
  mounted() {
    this.loadUsers();
    this.localPlans = _.cloneDeep(this.value);

    if (this.localPlans.length === 0) {
      this.addPlan();
    }
  },
  watch: {
    localPlans: {
      handler() {
        this.$emit('input', this.localPlans);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
    }),
    formattedUsers() {
      let output = this.$_.map(this.users, item => {
        return {
          value: item.id,
          text: item.attributes.name,
        }
      })

      /*
      output.push({
        divider: true
      })

      output.push({
        text: 'All Team Members (Default)',
        value: 'all'
      })
*/

      return output
    },
  }
}
</script>

<style lang="scss" scoped>
#introText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

#tableContainer {
  margin-top: 24px;
}

::v-deep #editorTable {
  thead {
    th {
      background: transparent !important;
      border: none !important;
      text-transform: uppercase;
    }
  }

  tbody {
    tr {
      td {
        border: none !important;
        vertical-align: top !important;
      }
    }
  }
}


::v-deep  .v-select__slot {
  /*  max-width: 300px !important;*/
}

::v-deep .v-select__selections {
    overflow-y: auto;
    max-height: 90px;
}
</style>
