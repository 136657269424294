import _ from 'lodash'

export default {
  data() {
    return {

    }
  },
  methods: {
    setFormException(exception) {
      this.validationExceptions = exception;
    },
    clearFormException() {
      this.validationExceptions = null;
    },
    fieldHasErrors(field) {
      return this.getFieldErrors(field).length > 0;
    },
    getFieldErrors(field) {
      let errors = this.$_.get(this?.validationExceptions?.response?.data?.errors, field, []);

        let ending = field.split('.').pop().split('_').join(' ');

        return _.map(errors, (error) => {
          return error.replace(field, ending);
        });

      return errors;
    }
  },
}
