<template>
  <div>
    <div id="payStructurePanel">
      <h3 id="pageTitleContainer">
        <span id="pageNumber">03</span>
        <span id="pageTitle">Setup Pay Structure</span>
      </h3>
      <div v-if="loading.configuration === false">
        <single-plan-editor v-if="displayType === 'single'"
                            @changePlanType="changePlanType"
                            :validationExceptions="validationExceptions"
                            v-model="plans"
        />
        <multi-plan-editor v-if="displayType === 'multiple'"
                           @changePlanType="changePlanType"
                           :validationExceptions="validationExceptions"
                           v-model="plans"
        />
      </div>
    </div>

    <div id="nextButtonPanel">
      <v-btn plain color="info" @click.prevent="$emit('goToStep',2)">
        <v-icon>
          arrow_back
        </v-icon>
        Back
      </v-btn>
      <v-btn
        @click.prevent="saveAndContinue"
        :loading="saving"
        color="primary">
        Save & Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SinglePlanEditor from "./payStructure/SinglePlanEditor";
import MultiPlanEditor from "./payStructure/MultiPlanEditor";
import HasFormErrors from "@/config/mixins/HasFormErrors";
import api from '@/api'

export default {
  components: {MultiPlanEditor, SinglePlanEditor},
  mixins: [HasFormErrors],
  data() {
    return {
      plans: [],
      loading: {
        configuration: true,
        plans: true
      },
      saving: false
    }
  },
  methods: {
    saveAndContinue() {
      this.saving = true;

      window.scrollTo(0, 0);

      this.$api.commissionSetup.savePlans({
        type: this.displayType,
        plans: this.plans
      }).then(({data}) => {

        this.loadCommissionSetup().then(() => {
          this.$emit('goToStep', 4);
        });
      }).catch(validationException => {
        this.setFormException(validationException)
        this.saving = false;
      });
    },
    changePlanType(type) {
      this.loading.configuration = true;
      this.$api.commissionSetup.savePlanType({
        type: type,
      }).then((response) => {
        this.loadCommissionSetup().then(() => {
          this.loading.configuration = false;
        });
      });
    },
    loadPlans() {
      this.plans = []
      this.$_.forEach(this.commissionSetup.plans, plan => {
        let planOutput = {
          id: plan.id,
          //all: plan.attributes.all_users,
          type: 'existing',
          name: plan.attributes.name,
          new_commission_percentage: plan.attributes.new_percentage,
          renewal_commission_percentage: plan.attributes.renewal_percentage,
          users: [],
        }

        this.$_.forEach(plan?.related?.users?.data, user => {
          planOutput.users.push(user.id)
        })

        this.plans.push(planOutput)
      })

      this.loading.plans = false

      this.$nextTick(() => {
        this.hasDirtyData = false
      })

      return this
    },
    ...mapActions({
      loadCommissionSetup: 'commissions/loadCommissionSetup',
    })
  },
  mounted() {
    this.loadCommissionSetup().then(() => {
      this.loading.configuration = false;

      if (this.$route.query.type === this.commissionSetup.commission_plan_type) {
        this.loadPlans();
      }
    });
  },
  watch: {
    '$route.query.type': function (type) {
      if (type === this.commissionSetup.commission_plan_type) {
        this.loadPlans();
      } else {
        this.plans = [];
      }
    }
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
    }),
    displayType() {
      if (this.$route.query.type) {
        return this.$route.query.type;
      } else {
        return this.commissionSetup.commission_plan_type;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#nextButtonPanel {
  background: #FFFFFF;
  padding: 16px 32px;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}

#payStructurePanel {
  background: #FFFFFF;
  padding: 16px 32px;
  margin-bottom: 8px;
}
</style>
