<template>
  <div>
    <div id="introText">
      Name your pay plan and add percentages.
    </div>

    <div id="tableContainer">
      <v-simple-table id="editorTable">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Pay Structure Name</th>
            <th class="text-left">New Business Rate (NBS)</th>
            <th class="text-left">Renewal Business Rate (RWL)</th>
            <th class="text-left">Team Members</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(plan, index) in localPlans" :key="plan.id">
            <td class="text-left">
              <v-text-field
                outlined
                dense
                placeholder="Plan Name"
                v-model="plan.name"
                :error-messages="getFieldErrors('plans.' + index + '.name')"
                :error="fieldHasErrors('plans.' + index + '.name')"
              />
            </td>
            <td class="text-left">
              <v-text-field
                outlined
                dense
                placeholder="Percentage"
                suffix="%"
                v-model="plan.new_commission_percentage"
                :error-messages="getFieldErrors('plans.' + index + '.new_commission_percentage')"
                :error="fieldHasErrors('plans.' + index + '.new_commission_percentage')"
              />
            </td>
            <td class="text-left">
              <v-text-field
                outlined
                dense
                placeholder="Percentage"
                suffix="%"
                v-model="plan.renewal_commission_percentage"
                :error-messages="getFieldErrors('plans.' + index + '.renewal_commission_percentage')"
                :error="fieldHasErrors('plans.' + index + '.renewal_commission_percentage')"
              />
            </td>
            <td class="text-left">
              <v-text-field
                outlined
                dense
                hide-details
                placeholder="All Producers"
                value="All Producers"
                readonly
                disabled
              />
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="mt-8">
        <v-btn plain color="info" @click.prevent="changeToMultiplePayPlan">
          Change to Multiple Pay Plan
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import HasFormErrorsMethodsOnly from "@/config/mixins/HasFormErrorsMethodsOnly";
export default {
  mixins:[HasFormErrorsMethodsOnly],
  props:[
    'value','validationExceptions'
  ],
  data(){
    return {
      icons:{

      },
      localPlans:[]
    }
  },
  methods: {
    changeToMultiplePayPlan(){
      this.$router.push({
        name: 'commission-settings.pay-structure',
        query: {
          type: 'multiple',
        },
      })
    },
    addPlan(){
      this.localPlans.push({
        id: Math.random(),
        name:'',
        new_commission_percentage: '0',
        renewal_commission_percentage: '0',
        teamMembers:[]
      })
    },
    removePlan(plan){
      this.localPlans = _.without(this.localPlans,plan);
    }
  },
  mounted() {
    this.localPlans = _.cloneDeep(this.value);

    if(this.localPlans.length === 0){
      this.addPlan();
    }
  },
  watch:{
    localPlans:{
      handler(){
        this.$emit('input',this.localPlans);
      },
      deep:true
    }
  }
}
</script>

<style lang="scss" scoped>
#introText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

#tableContainer {
  margin-top: 24px;
}

::v-deep #editorTable {
  thead {
    th {
      background: transparent !important;
      border: none !important;
      text-transform: uppercase;
    }
  }


  tbody {
    tr {
      td {
        border: none !important;
        vertical-align: top !important;
      }
    }
  }
}
</style>
